import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import './styles.css';

export default function RadioInput({
  label,
  disabled,
  value,
  checked,
  register,
  onChange,
  className,
  isDirty,
}: {
  label?: string;
  disabled?: boolean;
  value?: string;
  checked?: boolean;
  register?: UseFormRegisterReturn;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  isDirty?: boolean;
}) {
  return (
    <div>
      <label className='container'>
        {register ? (
          <input
            id={`${value}`}
            disabled={disabled}
            {...register}
            value={value}
            type='radio'
            checked={checked}
            className={`checkmark ${className} ${isDirty ? 'dirty' : ''}`}
          />
        ) : (
          <input
            onChange={onChange}
            disabled={disabled}
            id={`$${value}`}
            value={value}
            type='radio'
            checked={checked}
            className={`checkmark ${className} ${isDirty ? 'dirty' : ''}`}
          />
        )}{' '}
        {label}
      </label>
    </div>
  );
}
