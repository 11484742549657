import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'Condition', accessor: 'condition' },
  { Header: 'Display Name', accessor: 'condition_display_name' },
  { Header: 'Age Low', accessor: 'age_low' },
  { Header: 'Age High', accessor: 'age_high' },
];

export default function ConditionMetrics() {
  return (
    <PageListView
      header='Condtion Metrics (V2)'
      rightButton={{
        title: 'Create New',
        url: '/conditionMetrics/new',
      }}
      tableProps={{
        type: 'conditionMetrics',
        route: 'conditionMetrics',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Condition',
            filter: 'condition',
          },
          {
            label: 'Display Name',
            filter: 'condition_display_name',
          },
        ],
      }}
    />
  );
}
