import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import TextInput from '../../components/TextInput/TextInput';

import { useForm } from 'react-hook-form';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

interface ConditionMetricData {
  id: string;
  condition: string;
  condition_display_name: string;
  age_low: number;
  age_high: number;
  display_order: string;
  about: string;
  faq: string;
  references: string;
}
export default function ConditionMetric() {
  const route = 'conditionMetrics';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling<Partial<ConditionMetricData>>(
    route,
    id,
    'conditionMetric',
  );

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      condition: '',
      condition_display_name: '',
      age_low: '',
      age_high: '',
      display_order: '',
      about: '',
      faq: '',
      references: '',
    },
  });

  const dirtyFields = formState.dirtyFields;

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      condition: params.condition,
      condition_display_name: params.condition_display_name,
      age_low: params.age_low,
      age_high: params.age_high,
      display_order: params.display_order,
      about: params.about,
      faq: params.faq,
      references: params.references,
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
        reset(undefined, { keepValues: true, keepDirty: false });
      } else {
        await fetchData();
        reset(undefined, { keepValues: true, keepDirty: false });
      }
    }
  };

  return (
    <PageDetailView
      header={`Condition Metrics (V2) ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={handleSubmit(onSave)}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Details'}>
          <TextInput
            title='Condition'
            placeholder='Condition'
            value={params?.condition ?? ''}
            isDirty={dirtyFields.condition}
            register={{
              ...register('condition', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    condition: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Display Name'
            placeholder='Display Name'
            value={params?.condition_display_name ?? ''}
            isDirty={dirtyFields.condition_display_name}
            register={{
              ...register('condition_display_name', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    condition_display_name: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            type='number'
            title='Age Low'
            placeholder='Age Range Low'
            value={params?.age_low ?? ''}
            isDirty={dirtyFields.age_low}
            register={{
              ...register('age_low', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    age_low: Number(e.target.value),
                  })),
              }),
            }}
          />

          <TextInput
            type='number'
            title='Age High'
            placeholder='Age Range High'
            value={params?.age_high ?? ''}
            isDirty={dirtyFields.age_high}
            register={{
              ...register('age_high', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    age_high: Number(e.target.value),
                  })),
              }),
            }}
          />

          <TextInput
            title='Display Order'
            placeholder='Display Order'
            value={params?.display_order}
            isDirty={dirtyFields.display_order}
            register={{
              ...register('display_order', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState!,
                    display_order: e.target.value.replace(/\D/, ''),
                  })),
              }),
            }}
          />

          <PageDetailCard
            column={1}
            title={'About'}
            innerClassName={dirtyFields.about ? 'dirty' : ''}
          >
            <MarkdownEditor
              className='dirty'
              height={300}
              value={params?.about}
              onChange={value => {
                setParams(prevState => ({
                  ...prevState,
                  about: value,
                }));
                setValue('about', value, { shouldDirty: true });
              }}
              highlightEnable={false}
              autoSyncScroll={true}
            />
          </PageDetailCard>

          <PageDetailCard
            column={1}
            title={'FAQ'}
            innerClassName={dirtyFields.faq ? 'dirty' : ''}
          >
            <MarkdownEditor
              height={300}
              value={params?.faq}
              onChange={value => {
                setParams(prevState => ({
                  ...prevState,
                  faq: value,
                }));
                setValue('faq', value, { shouldDirty: true });
              }}
              highlightEnable={false}
              autoSyncScroll={true}
            />
          </PageDetailCard>

          <PageDetailCard
            column={1}
            title={'References'}
            innerClassName={dirtyFields.references ? 'dirty' : ''}
          >
            <MarkdownEditor
              height={300}
              value={params?.references}
              onChange={value => {
                setParams(prevState => ({
                  ...prevState,
                  references: value,
                }));
                setValue('references', value, { shouldDirty: true });
              }}
              highlightEnable={false}
              autoSyncScroll={true}
            />
          </PageDetailCard>
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
