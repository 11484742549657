import { parse, serialize, CookieSerializeOptions } from 'cookie';

// Cookie name for impersonation
export const IMPERSONATION_COOKIE_NAME = 'x-impersonate-id';

// Cookie options type
export type CookieOptions = CookieSerializeOptions;

/**
 * Check if we're in a browser environment
 */
const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined';

/**
 * Initialize vinxi/http utilities for server-side cookie handling if available
 */
let vinxiHttp: {
  getCookie?: Function;
  setCookie?: Function;
  deleteCookie?: Function;
} = {};

// Only try to load vinxi/http in a server environment
if (!isBrowser) {
  try {
    // Use CommonJS require for server environments
    // This will be properly tree-shaken in client builds
    // @ts-ignore - Dynamically importing module
    vinxiHttp = require('vinxi/http');
  } catch (e) {
    console.warn(
      'vinxi/http not available, falling back to cookie-only implementation',
    );
  }
}
/**
 * Set impersonation cookie for partner impersonation
 * @param partnerId - ID of the partner to impersonate
 * @param options - Optional cookie options
 */
export function setImpersonationCookie(
  partnerId: string,
  options?: CookieOptions,
): void {
  const cookieOptions: CookieOptions = {
    // Allow the cookie to be accessed across subdomains
    domain: options?.domain || '.tinyhealth.com',
    // Set secure in production environments
    secure: options?.secure,
    // Use lax to allow the cookie to be sent when navigating to the site
    sameSite: 'lax' as const,
    // Set a short expiration (60 minutes)
    maxAge: 60 * 60,
    path: '/',
    ...(options || {}),
  };
  // Use the appropriate method based on environment
  if (isBrowser) {
    // Client-side: use document.cookie
    const serializedCookie = serialize(
      IMPERSONATION_COOKIE_NAME,
      partnerId,
      cookieOptions,
    );
    document.cookie = serializedCookie;
  } else if (vinxiHttp?.setCookie) {
    // Server-side: use vinxi/http if available
    vinxiHttp.setCookie(IMPERSONATION_COOKIE_NAME, partnerId, cookieOptions);
  } else {
    // Server-side without vinxi: can't set cookies directly
    console.warn(
      'setImpersonationCookie called in server context without vinxi/http',
    );
  }
}

/**
 * Get impersonation cookie value
 * @param serverCookieHeader - Optional cookie header for server-side contexts
 * @returns The partner ID being impersonated, or undefined if not impersonating
 */
export function getImpersonationCookie(
  serverCookieHeader?: string | null,
): string | undefined {
  if (isBrowser) {
    // Client-side: use document.cookie
    const cookies = parse(document.cookie);
    return cookies[IMPERSONATION_COOKIE_NAME];
  } else if (vinxiHttp?.getCookie) {
    // Server-side: use vinxi/http if available
    return vinxiHttp.getCookie(IMPERSONATION_COOKIE_NAME);
  } else if (serverCookieHeader) {
    // Server-side without vinxi but with cookie header: parse manually
    const cookies = parse(serverCookieHeader);
    return cookies[IMPERSONATION_COOKIE_NAME];
  }

  // No way to get the cookie
  return undefined;
}

/**
 * Delete impersonation cookie
 */
export function deleteImpersonationCookie(): void {
  const cookieOptions: CookieOptions = {
    // Set expiration in the past to delete the cookie
    expires: new Date(0),
    path: '/',
  };

  // Use the appropriate method based on environment
  if (isBrowser) {
    // Client-side: use document.cookie
    document.cookie = serialize(IMPERSONATION_COOKIE_NAME, '', cookieOptions);
  } else if (vinxiHttp?.deleteCookie) {
    // Server-side: use vinxi/http if available
    vinxiHttp.deleteCookie(IMPERSONATION_COOKIE_NAME, cookieOptions);
  } else {
    // Server-side without vinxi: can't delete cookies directly
    console.warn(
      'deleteImpersonationCookie called in server context without vinxi/http',
    );
  }
}

// Server-side cookie parsing (for middleware or server components)
export function parseImpersonationCookie(
  cookieHeader?: string,
): string | undefined {
  if (!cookieHeader) return undefined;

  const cookies = parse(cookieHeader);
  return cookies[IMPERSONATION_COOKIE_NAME];
}

// Server-side cookie serialization (for setting cookies in responses)
export function serializeImpersonationCookie(
  partnerId: string,
  options?: CookieOptions,
): string {
  const cookieOptions: CookieOptions = {
    // Allow the cookie to be accessed across subdomains
    domain: options?.domain || '.tinyhealth.com',
    // Set secure in production environments
    secure: options?.secure,
    // Use lax to allow the cookie to be sent when navigating to the site
    sameSite: 'lax' as const,
    // Set a short expiration (60 minutes)
    maxAge: 60 * 60,
    // Allow JavaScript access
    httpOnly: false,
    path: '/',
    ...(options || {}),
  };

  return serialize(IMPERSONATION_COOKIE_NAME, partnerId, cookieOptions);
}

/**
 * Check if impersonation is active and get impersonation details
 * @param serverCookieHeader - Optional cookie header for server-side contexts
 * @returns Impersonation details object with partnerId and isImpersonating flag
 */
export async function getImpersonationDetails(
  serverCookieHeader?: string,
): Promise<{
  partnerId: string;
  isImpersonating: boolean;
}> {
  const partnerId = await getImpersonationCookie(serverCookieHeader);
  return {
    partnerId: partnerId || '',
    isImpersonating: !!partnerId,
  };
}

/**
 * Check if impersonation is active
 * @param serverCookieHeader - Optional cookie header for server-side contexts
 * @returns True if impersonation is active, false otherwise
 */
export async function isImpersonating(
  serverCookieHeader?: string,
): Promise<boolean> {
  return (await getImpersonationCookie(serverCookieHeader)) !== undefined;
}
