import React, { useState } from 'react';
import {
  deleteImpersonationCookie,
  setImpersonationCookie,
  setAdminJWTCookie,
} from '@repo/utils';
import { jwtToken } from '../../lib/constants';

interface ImpersonateButtonProps {
  partnerId: string;
  partnerAdminUserId: string;
  partnerName?: string;
  buttonText?: string;
  buttonType?: 'link' | 'text' | 'default' | 'primary' | 'dashed';
  buttonSize?: 'small' | 'middle' | 'large';
}

/**
 * A button component that allows admins to impersonate a partner
 * Uses the admin_user_id for impersonation to ensure proper authentication
 */
const ImpersonatePartnerButton: React.FC<ImpersonateButtonProps> = ({
  partnerId,
  partnerAdminUserId,
  partnerName,
  buttonText,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleImpersonate = async () => {
    try {
      setIsLoading(true);

      if (!partnerAdminUserId) {
        console.error('Cannot impersonate partner without admin_user_id');
        alert(
          'This partner cannot be impersonated because it has no admin user.',
        );
        setIsLoading(false);
        return;
      }

      console.log(
        `Impersonating partner ${partnerId} with admin_user_id ${partnerAdminUserId}`,
      );
      const environment = process.env.REACT_APP_ENVIRONMENT;
      deleteImpersonationCookie();
      // Set the impersonation cookie with the admin_user_id
      const cookieOptions = {
        domain:
          environment === 'development'
            ? 'localhost'
            : environment === 'preview'
              ? 'test-tinyhealth.com'
              : '.tinyhealth.com',
        secure: true,
      };
      setImpersonationCookie(partnerAdminUserId, cookieOptions);

      // Store the admin's JWT token in a cookie for cross-domain access
      const adminToken = localStorage.getItem(jwtToken);
      if (adminToken) {
        setAdminJWTCookie(adminToken, cookieOptions);
      } else {
        console.error('No admin JWT token found in localStorage');
      }

      // Determine the partner portal URL based on environment
      let partnerPortalUrl = 'https://partner.tinyhealth.com/portal';
      if (environment === 'preview') {
        partnerPortalUrl = 'https://partner.test-tinyhealth.com/portal';
      } else if (environment === 'development') {
        partnerPortalUrl = 'http://localhost:3023/portal';
      }

      // Add a small delay to ensure cookies are set before opening the new tab
      setTimeout(() => {
        // Open the partner portal in a new tab
        window.open(partnerPortalUrl, '_blank');
      }, 250);
    } catch (error) {
      console.error('Error impersonating partner:', error);
      alert('Failed to impersonate partner. See console for details.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-300 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
      onClick={handleImpersonate}
      type='button'
      disabled={isLoading || !partnerAdminUserId}
    >
      {isLoading
        ? 'Loading...'
        : buttonText || `Impersonate ${partnerName || partnerId}`}
    </button>
  );
};

export default ImpersonatePartnerButton;
