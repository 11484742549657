import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  {
    Header: 'Display Title',
    accessor: 'display_title',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Sampling Group',
    accessor: 'sampling_group',
  },
  {
    Header: 'Version',
    accessor: 'v2',
    Cell: ({ value }) => (
      <div>
        <span>{value === 1 ? 'V2' : 'V1'}</span>
      </div>
    ),
  },
  {
    Header: 'Published',
    accessor: 'published',
    Cell: ({ value }) => (
      <div>
        <span>{value === 1 ? 'yes' : 'no'}</span>
      </div>
    ),
  },
];

export default function ActionPlanRecommendations() {
  return (
    <PageListView
      header='Action Plan Recommendations'
      rightButton={{
        title: 'Create New',
        url: '/actionPlanRecommendations/new',
      }}
      tableProps={{
        type: 'actionPlanRecommendations',
        route: 'actionPlanRecommendations',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Display Title',
            filter: 'display_title',
          },
          {
            label: 'Category',
            filter: 'category',
          },
          {
            label: 'Sampling Group',
            filter: 'sampling_group',
          },
        ],
      }}
    />
  );
}
