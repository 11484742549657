import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from 'react-spinners/HashLoader';

import { deleteAdminJWTCookie, deleteImpersonationCookie } from '@repo/utils';
import useStore from '../../stores';
import updateLocalToken from '../../lib/updateLocalToken';

import Logo from '../../assets/logo/logo.svg';

export default function Logout() {
  const history = useHistory();
  const setAdmin = useStore(state => state.admin.setAdmin);
  useEffect(() => {
    function logout() {
      updateLocalToken(null);
      deleteAdminJWTCookie();
      deleteImpersonationCookie();
      setAdmin(undefined, undefined);
      history.push('/login');
    }
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='flex flex-col m-0 p-4 border-0 h-full min-h-screen bg-grey-100'>
      <div className='flex flex-col p-2'>
        <div className='flex items-center'>
          <img src={Logo} className='h-12' alt='Tiny Ops' />
        </div>
      </div>
      <div className='my-10 mx-auto'>
        <div className='flex flex-col items-center justify-center'>
          <h1 className='m-0 text-4xl font-serif'>Logging out...</h1>
          <span className='mt-4 mb-8 leading-5'>
            This should only take a few moments...
          </span>
          <Loader color='#009b87' size='75px' />
        </div>
      </div>
    </div>
  );
}
