import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Select from 'react-select';

import useStore from '../../stores';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';

import { Icon } from '../../components/Icon/Icon';
import { ICON_SVG } from '../../helpers';

import moment from 'moment';

import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';

import { queryBuilder } from '../../lib/utils/queryBuilder';
import { API_PAGE_LIMIT } from '../../lib/constants';

export default function ActionPlanRecommendation() {
  const route = 'actionPlanRecommendations';

  const history = useHistory();
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    fetchDataAndReturn,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling(route, id, 'actionPlanRecommendation');
  const [guides, setGuides] = useState([]);
  const [recommendations, setRecommendations] = useState([]);
  const [mediaUploads, setMediaUploads] = useState([]);

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    }
    getOtherData();
  }, [id]);

  const onSave = async () => {
    const updatedObj = await saveData(params => ({
      about: params.about,
      category: params.category,
      cover_url_id:
        Number(params.cover_url_id) === 0 ? null : params.cover_url_id,
      detail: params.detail,
      display_order: params.display_order ? params.display_order : 0,
      display_tags: params.display_tags,
      display_title: params.display_title,
      evidence_rating: params.evidence_rating,
      faq: params.faq,
      instruction_1: params.instruction_1,
      instruction_1_icon: params.instruction_1_icon,
      instruction_2: params.instruction_2,
      instruction_2_icon: params.instruction_2_icon,
      instruction_3: params.instruction_3,
      instruction_3_icon: params.instruction_3_icon,
      subtitle: params.subtitle,
      published: params.published,
      v2: params.v2,
      guides_lookup:
        params.guides_lookup?.length > 0
          ? params.guides_lookup.map((obj, index) => {
              return Object.assign({}, obj, {
                display_order: index,
              });
            })
          : [],
      recommendations_lookup:
        params.recommendations_lookup?.length > 0
          ? params.recommendations_lookup.map((obj, index) => {
              return Object.assign({}, obj, {
                display_order: index,
              });
            })
          : [],
    }));

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
      }
    }
  };

  const getOtherData = async () => {
    const guidesList = await fetchDataAndReturn(
      `guides?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        orderBy: 'display_title',
        order: 'asc',
      })}`,
      'guides',
    );
    setGuides(guidesList || []);

    const recList = await fetchDataAndReturn(
      `recommendations?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        orderBy: 'name',
        order: 'asc',
      })}`,
      'recommendations',
    );
    setRecommendations(recList || []);

    const mediaList = await fetchDataAndReturn(
      `mediaUploads?${queryBuilder({
        page: 0,
        pageLimit: API_PAGE_LIMIT,
        filterType: 'type',
        filterValue: 'action_plan_recommendations',
        orderBy: 'name',
        order: 'asc',
      })}`,
      'mediaUploads',
    );
    setMediaUploads(mediaList || []);
  };

  const iconList = [];
  const icon_names = Object.keys(ICON_SVG).sort();
  for (let icon_name of icon_names) {
    iconList.push({
      value: icon_name,
      label: (
        <div className='flex'>
          <Icon name={icon_name} size='s' />
          <span className='ml-2 text-sm'>{icon_name}</span>
        </div>
      ),
    });
  }

  let displayTagsList = [
    { value: 'Probiotic', label: 'Probiotic' },
    { value: 'Supplement', label: 'Supplement' },
    { value: 'Food', label: 'Food' },
    { value: 'Recipe', label: 'Recipe' },
    { value: 'Product', label: 'Product' },
    { value: 'Tip', label: 'Tip' },
    { value: 'Resample', label: 'Resample' },
  ];

  const selectedGuides = [];
  if (params?.guides_lookup?.length > 0) {
    params.guides_lookup.map(obj => {
      if (!obj.deleted) {
        selectedGuides.push(obj.guide_id);
      }
    });
  }

  const selectedRecommendations = [];
  if (params?.recommendations_lookup?.length > 0) {
    params.recommendations_lookup.map(obj => {
      if (!obj.deleted) {
        selectedRecommendations.push(obj.guide_id);
      }
    });
  }

  return (
    <PageDetailView
      header={`Action Plan ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={onSave}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'Information'}>
          <TextInput
            title='Display Title'
            placeholder='Title'
            value={params?.display_title}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_title: e.target.value,
              }))
            }
          />

          <TextInput
            title='Subtitle'
            subtitle='Shows below title. Use asterisk for italics (e.g. *Bifidobacterium*) (Character limit 100)'
            placeholder='Subtitle'
            value={params?.subtitle}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                subtitle: e.target.value,
              }))
            }
          />

          <CheckboxInput
            title='Published'
            subtitle='(Only published will be displayed)'
            checked={params?.published}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                published: Number(e.target.checked),
              }))
            }
          />

          <CheckboxInput
            title='Use V2 Design'
            subtitle='(Temp field to help us switch from current format)'
            checked={params?.v2}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                v2: Number(e.target.checked),
              }))
            }
          />

          <SelectInput
            title='Category'
            options={[
              { value: '', title: '-- Choose a category --', disabled: true },
              { value: 'DietarySuggestions', title: 'DietarySuggestions' },
              { value: 'LifestyleSuggestions', title: 'LifestyleSuggestions' },
              {
                value: 'SupplementSuggestions',
                title: 'SupplementSuggestions',
              },
              { value: 'TakeNote', title: 'TakeNote' },
              { value: 'WhenToResample', title: 'WhenToResample' },
              { value: 'NeedsAttention', title: 'NeedsAttention' },
            ]}
            value={params?.category ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                category: e.target.value,
              }))
            }
          />

          <TextInput
            title='Display Order'
            placeholder='Sort Order'
            value={params?.display_order}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                display_order: e.target.value.replace(/\D/, ''),
              }))
            }
          />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='p-0 text-sm'>Cover Image</label>
              <label className='mb-1 p-0 text-xs opacity-60'>
                Illustration that will appear on the top
              </label>
              <select
                value={params?.cover_url_id}
                name={'CoverImage'}
                className='flex-auto relative w-full m-0 mb-1 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                onChange={e => {
                  setParams(prevState => ({
                    ...prevState,
                    cover_url_id: e.target.value,
                  }));
                }}
              >
                <option selected value={0}>
                  -- Choose an image --
                </option>

                {mediaUploads.map(media => {
                  return (
                    <option key={media.id} value={media.id}>
                      {media.name}
                    </option>
                  );
                })}
              </select>
              {(() => {
                if (Number(params?.cover_url_id) > 0) {
                  const media = mediaUploads.find(media => {
                    return Number(media.id) === Number(params?.cover_url_id);
                  });

                  if (media) {
                    return (
                      <div className='relative inline-block w-28 mb-2'>
                        <img
                          src={media.url}
                          alt='Image Url'
                          className='w-full h-auto'
                        />
                      </div>
                    );
                  }
                }
              })()}
            </div>
          </div>

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Display Tags</label>
              <Select
                className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                classNamePrefix='select'
                value={displayTagsList.filter(obj =>
                  params?.display_tags?.split(',').includes(obj.value),
                )}
                isClearable={true}
                isSearchable={true}
                name='display_tags'
                placeholder='Display Tags'
                options={displayTagsList}
                isMulti
                onChange={e =>
                  setParams(prevState => ({
                    ...prevState,
                    display_tags: e.map(obj => obj.value).join(),
                  }))
                }
              />
            </div>
          </div>

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Instruction 1</label>
              <label className='mb-1 p-0 text-xs opacity-60'>
                (Character limit 45)
              </label>
              <div className='flex'>
                <div className='flex-1'>
                  <Select
                    className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                    classNamePrefix='select'
                    value={iconList.find(
                      obj => obj.value === params?.instruction_1_icon,
                    )}
                    isClearable={true}
                    isSearchable={true}
                    name='instruction_1_icon'
                    placeholder='Icon'
                    options={iconList}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_1_icon: e?.value,
                      }))
                    }
                  />
                </div>
                <div className='ml-2 flex-2'>
                  <input
                    placeholder='Instruction 1'
                    value={params?.instruction_1}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_1: e.target.value,
                      }))
                    }
                    maxLength={45}
                    className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Instruction 2</label>
              <label className='mb-1 p-0 text-xs opacity-60'>
                (Character limit 45)
              </label>
              <div className='flex'>
                <div className='flex-1'>
                  <Select
                    className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                    classNamePrefix='select'
                    value={iconList.find(
                      obj => obj.value === params?.instruction_2_icon,
                    )}
                    isClearable={true}
                    isSearchable={true}
                    name='instruction_2_icon'
                    placeholder='Icon'
                    options={iconList}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_2_icon: e?.value,
                      }))
                    }
                  />
                </div>
                <div className='ml-2 flex-2'>
                  <input
                    placeholder='Instruction 2'
                    value={params?.instruction_2}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_2: e.target.value,
                      }))
                    }
                    maxLength={45}
                    className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Instruction 3</label>
              <label className='mb-1 p-0 text-xs opacity-60'>
                (Character limit 45)
              </label>
              <div className='flex'>
                <div className='flex-1'>
                  <Select
                    className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                    classNamePrefix='select'
                    value={iconList.find(
                      obj => obj.value === params?.instruction_3_icon,
                    )}
                    isClearable={true}
                    isSearchable={true}
                    name='instruction_3_icon'
                    placeholder='Icon'
                    options={iconList}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_3_icon: e?.value,
                      }))
                    }
                  />
                </div>
                <div className='ml-2 flex-2'>
                  <input
                    placeholder='Instruction 3'
                    value={params?.instruction_3}
                    onChange={e =>
                      setParams(prevState => ({
                        ...prevState,
                        instruction_3: e.target.value,
                      }))
                    }
                    maxLength={45}
                    className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                  />
                </div>
              </div>
            </div>
          </div>

          <SelectInput
            title='Evidence Rating'
            options={[
              { value: '', title: '-- Choose a rating --', disabled: true },
              { value: 'Strong', title: 'Strong' },
              { value: 'Good', title: 'Good' },
              {
                value: 'Emerging',
                title: 'Emerging',
              },
              { value: 'Limited', title: 'Limited' },
            ]}
            value={params?.evidence_rating ?? ''}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                evidence_rating: e.target.value,
              }))
            }
          />

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Guides</label>
              {params?.guides_lookup?.length > 0 ? (
                <>
                  {params?.guides_lookup.map((actionplan_guide, index) => {
                    if (actionplan_guide.deleted) {
                      return null;
                    }
                    return (
                      <div key={index} className={'flex justify-space'}>
                        <Select
                          className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                          classNamePrefix='select'
                          value={guides.find(
                            obj =>
                              parseInt(obj.id) ===
                              parseInt(actionplan_guide.guide_id),
                          )}
                          isClearable={false}
                          isSearchable={true}
                          name='guides'
                          placeholder='-- Choose a guide --'
                          options={guides}
                          getOptionLabel={option => option.display_title}
                          getOptionValue={option => option.id}
                          onChange={e => {
                            const selectedGuide = parseInt(e.id);
                            const newList = params?.guides_lookup.slice();

                            if (!selectedGuides.includes(selectedGuide)) {
                              if (newList[index]) {
                                newList[index].guide_id = selectedGuide;
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              guides_lookup: newList,
                            }));
                          }}
                        />
                        <button
                          type='button'
                          className='flex h-10 w-20 ml-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                          onClick={() => {
                            const newList = params?.guides_lookup.slice();

                            if (newList[index]) {
                              if (newList[index].id > 0) {
                                newList[index].deleted = true;
                              } else {
                                newList.splice(index, 1);
                              }
                            }
                            setParams(prevState => ({
                              ...prevState,
                              guides_lookup: newList,
                            }));
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    );
                  })}
                </>
              ) : null}
              <Select
                className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                classNamePrefix='select'
                value={0}
                isClearable={true}
                isSearchable={true}
                name='guides'
                placeholder='-- Choose a guide --'
                options={guides}
                getOptionLabel={option => option.display_title}
                getOptionValue={option => option.id}
                onChange={e => {
                  const selectedGuide = parseInt(e.id);
                  const newList =
                    params?.guides_lookup?.length > 0
                      ? params?.guides_lookup.slice()
                      : [];

                  if (!selectedGuides.includes(selectedGuide)) {
                    newList.push({
                      id: -1,
                      action_plan_recommendation_id: params?.id,
                      guide_id: parseInt(e.id),
                    });
                  }
                  setParams(prevState => ({
                    ...prevState,
                    guides_lookup: newList,
                  }));
                }}
              />
            </div>
          </div>

          <div className='flex-1 max-w-full mt-4'>
            <div className='flex flex-col relative'>
              <label className='mb-1 p-0 text-sm'>Recommendation Lists</label>
              {params?.recommendations_lookup?.length > 0 ? (
                <>
                  {params?.recommendations_lookup.map(
                    (actionplan_recommendation, index) => {
                      if (actionplan_recommendation.deleted) {
                        return null;
                      }
                      return (
                        <div key={index} className={'flex justify-space'}>
                          <Select
                            className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                            classNamePrefix='select'
                            value={recommendations.find(
                              obj =>
                                parseInt(obj.id) ===
                                parseInt(
                                  actionplan_recommendation.recommendation_id,
                                ),
                            )}
                            isClearable={false}
                            isSearchable={true}
                            name='recommendations'
                            placeholder='-- Choose a recommendation --'
                            options={recommendations}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.id}
                            onChange={e => {
                              const selectedRecommendation = parseInt(e.id);
                              const newList =
                                params?.recommendations_lookup.slice();

                              if (
                                !selectedRecommendations.includes(
                                  selectedRecommendation,
                                )
                              ) {
                                if (newList[index]) {
                                  newList[index].recommendation_id =
                                    selectedRecommendation;
                                }
                              }
                              setParams(prevState => ({
                                ...prevState,
                                recommendations_lookup: newList,
                              }));
                            }}
                          />
                          <button
                            type='button'
                            className='flex h-10 w-20 ml-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
                            onClick={() => {
                              const newList =
                                params?.recommendations_lookup.slice();

                              if (newList[index]) {
                                if (newList[index].id > 0) {
                                  newList[index].deleted = true;
                                } else {
                                  newList.splice(index, 1);
                                }
                              }
                              setParams(prevState => ({
                                ...prevState,
                                recommendations_lookup: newList,
                              }));
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      );
                    },
                  )}
                </>
              ) : null}

              <Select
                className='flex-auto relative w-full m-0 mb-1 rounded-md text-sm'
                classNamePrefix='select'
                value={0}
                isClearable={true}
                isSearchable={true}
                name='recommendations'
                placeholder='-- Choose a recommendation --'
                options={recommendations}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                onChange={e => {
                  const selectedRecommendation = parseInt(e.id);
                  const newList =
                    params?.recommendations_lookup?.length > 0
                      ? params?.recommendations_lookup.slice()
                      : [];

                  if (
                    !selectedRecommendations.includes(selectedRecommendation)
                  ) {
                    newList.push({
                      id: -1,
                      action_plan_recommendation_id: params?.id,
                      recommendation_id: parseInt(e.id),
                    });
                  }
                  setParams(prevState => ({
                    ...prevState,
                    recommendations_lookup: newList,
                  }));
                }}
              />
            </div>
          </div>
        </PageDetailCard>
        <PageDetailCard column={1} title={'Details'}>
          <MarkdownEditor
            height={300}
            value={params?.detail}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                detail: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>

        <PageDetailCard
          column={1}
          title={'About'}
          subtitle={`First paragraph will display (up to first line
                              break) with a "Learn more" after to show remaining
                              text in a modal`}
        >
          <MarkdownEditor
            height={300}
            value={params?.about}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                about: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>

        <PageDetailCard
          column={1}
          title={'FAQ'}
          subtitle='Use #### for questions followed by a line break
                              then the answer'
        >
          <MarkdownEditor
            height={300}
            value={params?.faq}
            onChange={value => {
              setParams(prevState => ({
                ...prevState,
                faq: value,
              }));
            }}
            highlightEnable={false}
            autoSyncScroll={true}
          />
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
