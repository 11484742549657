export enum AgeGroup {
  BABY = 'baby',
  TODDLER = 'toddler',
  CHILD = 'child',
  ADULT = 'adult',
}

export const getAgeGroup = (age: number): AgeGroup => {
  if (age < 365) {
    return AgeGroup.BABY;
  } else if (age < 1095) {
    return AgeGroup.TODDLER;
  } else if (age < 6570) {
    return AgeGroup.CHILD;
  } else {
    return AgeGroup.ADULT;
  }
};
