import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import useStore from '../../stores';

import TextInput from '../../components/TextInput/TextInput';
import CheckboxInput from '../../components/CheckboxInput/CheckboxInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import { Partner as PartnerType } from '../../pages/PartnerOrders';
import PageDetailView from '../../components/Page/PageDetailView';
import PageDetailContainer from '../../components/Page/PageDetailContainer';
import PageDetailCard from '../../components/Page/PageDetailCard';
import useDataHandling from '../../hooks/useDataHandling';
import Table from '../../components/Table';
import { emailTableColumns } from '../../pages/MainAccount/helper';
import RadioInput from '../../components/RadioInput/RadioInput';
import MarkdownEditor from '../../components/MarkdownEditor/MarkdownEditor';

export interface PortalConfig {
  disable_kit_activation: boolean;
}
export default function Partner() {
  const route = 'partners';

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const setRoute = useStore(state => state.route.setRoute);
  const {
    data: params,
    setData: setParams,
    fetchData,
    saveData,
    fetching,
    updated,
    errors,
  } = useDataHandling<Partial<PartnerType>>(route, id, 'partner');
  const [disabledFields, setDisabledFields] = useState<{
    slug: boolean;
    email: boolean;
    api_key: boolean;
  } | null>(null);

  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      api_key: params?.api_key ?? '',
      email: params?.email ?? '',
      kit_activation_instructions: params?.kit_activation_instructions ?? '',
      instructions_url: params?.instructions_url ?? '',
      light_logo: params?.light_logo ?? '',
      logo: params?.logo ?? '',
      admin_user_id: params?.admin_user_id ?? '',
      mainaccount_id: params?.mainaccount_id ?? '',
      name: params?.name ?? '',
      pdf_logo: params?.pdf_logo ?? '',
      portal_config: '',
      report_config: '',
      shipping_level: params?.shipping_level ?? '',
      slug: params?.slug ?? '',
      status: params?.status ?? '',
      typeform_adult_survey_id: params?.typeform_adult_survey_id ?? '',
      typeform_toddler_survey_id: params?.typeform_toddler_survey_id ?? '',
      typeform_vaginal_survey_id: params?.typeform_vaginal_survey_id ?? '',
      kitActivationField: '',
    },
  });

  const dirtyFields = formState.dirtyFields;

  useEffect(() => {
    setRoute(route);
    if (id !== 'new') {
      fetchData();
    } else {
      setParams({
        portal_config: { disable_kit_activation: false },
        report_config: {},
        hide_consults: 1,
      });
    }
  }, [id]);

  useEffect(() => {
    if (params?.id && !disabledFields) {
      setDisabledFields({
        slug: (params?.slug ?? '').length > 0,
        email: (params?.email ?? '').length > 0,
        api_key: (params?.api_key ?? '').length > 0,
      });
    }
  }, [params, disabledFields]);

  const onSave = async () => {
    const updatedObj = await saveData(params => {
      const {
        mainaccount_id,
        admin_user_id,
        kit_activation_instructions,
        typeform_adult_survey_id,
        typeform_toddler_survey_id,
        typeform_vaginal_survey_id,
        portal_config,
        report_config,
        ...rest
      } = params;

      return {
        ...rest,
        portal_config: portal_config
          ? typeof portal_config === 'string'
            ? (JSON.parse(portal_config) as PortalConfig)
            : portal_config
          : undefined,
        report_config: report_config
          ? typeof report_config === 'string'
            ? JSON.parse(report_config)
            : report_config
          : undefined,
        hide_consults: params?.hide_consults,
        admin_user_id: admin_user_id ?? undefined,
        kit_activation_instructions: kit_activation_instructions ?? undefined,
        mainaccount_id: mainaccount_id,
        typeform_adult_survey_id: typeform_adult_survey_id ?? undefined,
        typeform_toddler_survey_id: typeform_toddler_survey_id ?? undefined,
        typeform_vaginal_survey_id: typeform_vaginal_survey_id ?? undefined,
      };
    });

    if (updatedObj) {
      if (id === 'new') {
        history.push(`/${route}/${updatedObj.id}`);
      } else {
        await fetchData();
        setDisabledFields(null);
        reset();
      }
    }
  };

  const emails_sms = [...(params?.emails || []), ...(params?.sms || [])].sort(
    (a, b) => ((a?.created_at ?? 0) < (b?.created_at ?? 0) ? 1 : -1),
  );

  const kitActivationField = register('kitActivationField', {
    required: true,
    onChange: e => {
      const portal_config = params?.portal_config;
      portal_config.disable_kit_activation = e.target.value === 'true';

      setParams(prevState => ({
        ...prevState,
        portal_config: portal_config,
      }));
    },
  });

  return (
    <PageDetailView
      header={`Partner ${id}`}
      fetching={fetching}
      updated={updated}
      errors={errors}
      backUrl={route}
      fnSave={handleSubmit(onSave)}
    >
      <PageDetailContainer numColumns={1}>
        <PageDetailCard column={1} title={'General'}>
          <TextInput
            title='Name'
            placeholder='Name'
            value={params?.name ?? ''}
            isDirty={dirtyFields.name}
            register={{
              ...register('name', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    name: e.target.value,
                  })),
              }),
            }}
          />
          <SelectInput
            title='Status'
            options={[
              { value: '', title: '-- Choose a type --', disabled: true },
              { value: 'active', title: 'Active' },
              { value: 'inactive', title: 'Inactive' },
            ]}
            value={params?.status ?? ''}
            register={{
              ...register('status', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    status: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.status}
          />

          <TextInput
            title='Email'
            subtitle='The email where all partner notifications will go'
            placeholder='Email'
            value={params?.email ?? ''}
            disabled={false}
            register={{
              ...register('email', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    email: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.email}
          />

          <TextInput
            title='Admin User ID'
            placeholder='Admin User ID'
            subtitle='Partner admin that will be able to log in to the partner portal'
            value={params?.admin_user_id ?? ''}
            isDirty={dirtyFields.admin_user_id}
            register={{
              ...register('admin_user_id', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    admin_user_id: e.target.value,
                  })),
              }),
            }}
          />

          <TextInput
            title='Slug'
            subtitle='URL prefix for [slug].tinyhealth.com'
            placeholder='slug'
            value={params?.slug ?? ''}
            disabled={disabledFields?.slug}
            isDirty={dirtyFields.slug}
            register={{
              ...register('slug', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    slug: e.target.value
                      .trim()
                      .toLowerCase()
                      .replaceAll(' ', '')
                      .replaceAll('.', ''),
                  })),
              }),
            }}
          />

          <TextInput
            title='API Key'
            placeholder='API Key'
            value={params?.api_key ?? ''}
            disabled={disabledFields?.api_key}
            register={{
              ...register('api_key', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    api_key: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.api_key}
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'Fulfillment'}>
          <SelectInput
            title='Outbound Shipping Type'
            options={[
              { value: '', title: '-- Choose a type --', disabled: true },
              { value: 'usps_priority', title: 'USPS Priority' },
              {
                value: 'usps_ground_advantage',
                title: 'USPS Ground Advantage',
              },
            ]}
            value={params?.shipping_level ?? ''}
            register={{
              ...register('shipping_level', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    shipping_level: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.shipping_level}
          />

          <TextInput
            title='Instructions Url'
            placeholder='https://...'
            value={params?.instructions_url ?? ''}
            register={{
              ...register('instructions_url', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    instructions_url: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.instructions_url}
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'Kit Activation'}>
          <div>
            <RadioInput
              label='Users create individual accounts'
              value='false'
              checked={
                params?.portal_config.disable_kit_activation === false
              }
              register={kitActivationField}
            />
            <RadioInput
              label='Users activate kits without creating an account'
              value='true'
              checked={
                params?.portal_config.disable_kit_activation === true
              }
              register={kitActivationField}
            />
          </div>
          {params?.portal_config.disable_kit_activation === true ? (
            <div>
              <TextInput
                title='Main Account ID'
                placeholder='Main Account ID'
                subtitle='If kits are activated under a single mainaccount through typeform, put that mainaccount ID here'
                value={params?.mainaccount_id ?? ''}
                isDirty={dirtyFields.mainaccount_id}
                register={{
                  ...register('mainaccount_id', {
                    onChange: e =>
                      setParams(prevState => ({
                        ...prevState,
                        mainaccount_id: e.target.value,
                      })),
                  }),
                }}
              />
              <TextInput
                title='Child and Adult Gut Survey ID'
                placeholder='Child and Adult Gut Survey ID'
                value={params?.typeform_adult_survey_id ?? ''}
                isDirty={dirtyFields.typeform_adult_survey_id}
                register={{
                  ...register('typeform_adult_survey_id', {
                    onChange: e =>
                      setParams(prevState => ({
                        ...prevState,
                        typeform_adult_survey_id: e.target.value,
                      })),
                  }),
                }}
              />
              <TextInput
                title='Baby and Toddler Gut Survey ID'
                placeholder='Baby and Toddler Gut Survey ID'
                value={params?.typeform_toddler_survey_id ?? ''}
                isDirty={dirtyFields.typeform_toddler_survey_id}
                register={{
                  ...register('typeform_toddler_survey_id', {
                    onChange: e =>
                      setParams(prevState => ({
                        ...prevState,
                        typeform_toddler_survey_id: e.target.value,
                      })),
                  }),
                }}
              />
              <TextInput
                title='Adult Vaginal Survey ID'
                placeholder='Adult Vaginal Survey ID'
                value={params?.typeform_vaginal_survey_id ?? ''}
                isDirty={dirtyFields.typeform_vaginal_survey_id}
                register={{
                  ...register('typeform_vaginal_survey_id', {
                    onChange: e =>
                      setParams(prevState => ({
                        ...prevState,
                        typeform_vaginal_survey_id: e.target.value,
                      })),
                  }),
                }}
              />
              <div className='mt-4'>
                <label className='p-0 text-sm'>
                  Kit Activation Instructions
                </label>
              </div>
              <div>
                <label className='mb-1 p-0 text-xs opacity-60'>
                  Shows in Partner Portal
                </label>
              </div>
              <MarkdownEditor
                height={200}
                value={params?.kit_activation_instructions}
                onChange={value =>
                  setParams(prevState => ({
                    ...prevState,
                    kit_activation_instructions: value,
                  }))
                }
                highlightEnable={false}
                autoSyncScroll={true}
              />
            </div>
          ) : (
            ''
          )}
        </PageDetailCard>

        <PageDetailCard column={1} title={'Results Portal Config'}>
          <TextInput
            title='Logo'
            placeholder='Logo'
            value={params?.logo ?? ''}
            register={{
              ...register('logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.logo}
          />

          <TextInput
            title='Light Logo'
            placeholder='Light Logo'
            value={params?.light_logo ?? ''}
            register={{
              ...register('light_logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    light_logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.light_logo}
          />

          <TextInput
            type='textarea'
            title='Portal Config'
            placeholder='Portal Config'
            value={
              typeof params?.portal_config === 'object'
                ? JSON.stringify(params?.portal_config)
                : (params?.portal_config ?? {})
            }
            isDirty={dirtyFields.portal_config}
            register={{
              ...register('portal_config', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    portal_config: e.target.value,
                  })),
              }),
            }}
          />

          <CheckboxInput
            title='Hide Consults'
            checked={Boolean(params?.hide_consults)}
            onChange={e =>
              setParams(prevState => ({
                ...prevState,
                hide_consults: Number(e.target.checked),
              }))
            }
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'PDF Report'}>
          <TextInput
            title='PDF Logo'
            placeholder='PDF Logo'
            value={params?.pdf_logo ?? ''}
            register={{
              ...register('pdf_logo', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    pdf_logo: e.target.value,
                  })),
              }),
            }}
            isDirty={dirtyFields.pdf_logo}
          />

          <TextInput
            type='textarea'
            title='Report Config'
            subtitle='Configurations for the PDF report'
            placeholder='Report Config'
            value={
              typeof params?.report_config === 'object'
                ? JSON.stringify(params?.report_config)
                : (params?.report_config ?? {})
            }
            isDirty={dirtyFields.report_config}
            register={{
              ...register('report_config', {
                onChange: e =>
                  setParams(prevState => ({
                    ...prevState,
                    report_config: e.target.value,
                  })),
              }),
            }}
          />
        </PageDetailCard>

        <PageDetailCard column={1} title={'Automated Email/SMS history'}>
          {emails_sms.length > 0 ? (
            <Table
              type={'emails'}
              columns={emailTableColumns}
              staticData={emails_sms}
              disabled={() => true}
            ></Table>
          ) : null}
        </PageDetailCard>
      </PageDetailContainer>
    </PageDetailView>
  );
}
