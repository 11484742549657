import { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';

import { updateDateParam } from '../../helpers';
import {
  kitStatuses,
  kitVoidReasons,
  validLabPartners,
} from '../../lib/constants';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import X from '../../assets/iconography/x.svg';
import { ReactComponent as Check } from '../../assets/iconography/check.svg';
import { ReactComponent as Circle } from '../../assets/iconography/circle.svg';
import Table from '../../components/Table';
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import { queryBuilder } from '../../lib/utils/queryBuilder';
import PractitionerDropdown from '../../components/PractitionerDropdown/PractitionerDropdown';
import PartnerDropdown from '../../components/PartnerDropdown/PartnerDropdown';

import { feedbackTableColumns, npsTableColumns } from './helper';

import { checkValidDates } from '../../helpers';

import moment from 'moment';

export default function Kit() {
  const { id } = useParams();
  const setRoute = useStore(state => state.route.setRoute);
  const token = useStore(state => state.admin.token);
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [fetching, setFetching] = useState(true);
  const [fetchingResearchStudies, setFetchingResearchStudies] = useState(false);
  const [fetchingFeedback, setFetchingFeedback] = useState(true);
  const [fetchingNps, setFetchingNps] = useState(true);
  const [fetchingActiveSurveys, setFetchingActiveSurveys] = useState(true);
  const [fetchingPractitioners, setFetchingPractitioners] = useState(true);
  const [fetchingPartners, setFetchingPartners] = useState(true);

  const [deactivateOverlay, setDeactivateOverlay] = useState(false);
  const [deletePDFOverlay, setDeletePDFOverlay] = useState(false);
  const [params, setParams] = useState({});
  const [variant, setVariant] = useState(null);
  const [feedback, setFeedback] = useState([]);
  const [nps, setNps] = useState([]);
  const [activeSurveys, setActiveSurveys] = useState([]);
  const [researchStudies, setResearchStudies] = useState([]);
  const [practitioners, setPractitioners] = useState([]);
  const [partners, setPartners] = useState([]);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [errors, setErrors] = useState([]);
  const [feedbackErrors, setFeedbackErrors] = useState([]);
  const [npsErrors, setNpsErrors] = useState([]);
  const [activeSurveysErrors, setActiveSurveysErrors] = useState([]);
  const [researchStudiesErrors, setResearchStudiesErrors] = useState([]);
  const [practitionersErrors, setPractitionersErrors] = useState([]);
  const [partnersErrors, setPartnersErrors] = useState([]);

  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      comments: params.comments,
      first_name: params.first_name,
      last_name: params.last_name,
      membership_plan: params.membership_plan,
      membership_type: params.membership_type,
    },
  });

  const dirtyFields = formState.dirtyFields;

  const updateKit = async () => {
    setErrors([]);
    setUpdated(false);

    try {
      checkValidDates(params, [
        'date_purchased',
        'date_in_transit_to_customer',
        'date_delivered_to_customer',
        'date_activated',
        'date_in_transit_to_lab',
        'date_delivered_to_lab',
        'date_sequences_delivered',
        'date_pipeline_complete',
        'date_results_ready_practitioner',
        'date_results_ready',
        'date_expert_review_complete',
        'date_return_missing',
        'date_bioinformatics_hold',
        'date_voided',
      ]);

      if (variant) {
        checkValidDates(variant, [
          'sample_dates',
          'date_delivered_to_lab',
          'date_results_ready',
        ]);
      }
    } catch (err) {
      setErrors([err.message]);
      return;
    }

    const update_params = Object.assign({}, params, {
      variant,
    });

    if (update_params.status === 'void' && !update_params.void_reason) {
      setErrors(['Void Reason is required']);
      return;
    }

    if (update_params.status === 'void') {
      if (!update_params.void_reason) {
        setErrors(['Void Reason is required']);
        return;
      }

      if (!update_params.date_voided) {
        update_params.date_voided = moment().format('YYYY-MM-DD');
      }
    } else {
      update_params.date_voided = null;
      update_params.void_reason = null;
    }

    const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(update_params),
    });
    if (res.status === 200) {
      setUpdated(true);
      reset();
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  const deactivateKit = async () => {
    setErrors([]);
    setUpdated(false);
    const res = await fetch(
      `${API_BASE_PATH}/ops/admins/kits/deactivate/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      },
    );
    if (res.status === 200) {
      setDeactivateOverlay(false);
      setUpdated(true);
      window.scrollTo(0, 0);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  const generatePDF = async () => {
    setErrors([]);
    setUpdated(false);
    setIsGeneratingPDF(true);

    const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}/pdf`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      for (let attempt = 1; attempt <= 6; attempt++) {
        //wait 10 seconds before checking if PDF is generated
        await new Promise(resolve => setTimeout(resolve, 10000));

        const { pdf_url } = await getPDFSignedUrl();
        if (pdf_url) {
          setParams(prevState => ({
            ...prevState,
            pdf_url,
          }));
          break;
        }
      }
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }

    setIsGeneratingPDF(false);
  };
  const getPDFSignedUrl = async () => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}/pdf`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status === 200) {
      const { pdf_url } = await res.json();
      return { res, pdf_url };
    }
    return { res, pdf_url: null };
  };
  const viewPDF = async () => {
    setErrors([]);
    setUpdated(false);
    const { res, pdf_url } = await getPDFSignedUrl();
    if (res.status === 200) {
      window.open(pdf_url, '_blank');
      window.scrollTo(0, 0);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  const deletePDF = async () => {
    setErrors([]);
    setUpdated(false);
    const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}/pdf`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    });
    if (res.status >= 200 && res.status <= 299) {
      setDeletePDFOverlay(false);
      setParams(prevState => ({
        ...prevState,
        pdf_url: null,
      }));
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setErrors([
        'There was a problem loading your information, please try again later or contact support.',
      ]);
    } else {
      const { error } = await res.json();
      setErrors(prevArray => [...prevArray, error]);
    }
  };
  useEffect(() => {
    async function getKit() {
      setFetching(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const { kit } = await res.json();
        if (kit.variant) {
          setVariant(kit.variant);
          delete kit.variant;
        }
        setParams(kit);
        //prepare breadcrumbs
        let breadcrumbsArray = [];
        if (kit?.mainaccount?.id) {
          breadcrumbsArray.push({
            display: kit.mainaccount.email,
            link: `/mainaccounts/${kit.mainaccount.id}`,
          });
        } else {
          breadcrumbsArray.push({ display: 'no mainaccount yet' });
        }
        if (kit?.tinyaccount?.id) {
          breadcrumbsArray.push({
            display:
              kit.tinyaccount.first_name + ' ' + kit.tinyaccount.last_name,
            link: `/tinyaccounts/${kit.tinyaccount_id}`,
          });
        } else {
          breadcrumbsArray.push({ display: 'no tinyaccount yet' });
        }
        breadcrumbsArray.push({ display: kit.id, last: true });
        setBreadcrumbs(breadcrumbsArray);
        setFetching(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetching(false);
      } else {
        const { error } = await res.json();
        setErrors(prevArray => [...prevArray, error]);
        setFetching(false);
      }
    }
    setRoute('kits');
    getKit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getKitFeedbackList() {
      setFetchingFeedback(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/kits/${id}/feedback`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { feedback } = await res.json();
        setFeedback(feedback);
        //prepare breadcrumbs
        let breadcrumbsArray = [];
        setFetchingFeedback(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setFeedbackErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetchingFeedback(false);
      } else {
        const { error } = await res.json();
        setFeedbackErrors(prevArray => [...prevArray, error]);
        setFetchingFeedback(false);
      }
    }
    getKitFeedbackList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getKitNps() {
      setFetchingNps(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/kits/${id}/nps`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const { nps } = await res.json();
        setNps(nps);
        //prepare breadcrumbs
        let breadcrumbsArray = [];
        setFetchingNps(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setNpsErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetchingNps(false);
      } else {
        const { error } = await res.json();
        setNpsErrors(prevArray => [...prevArray, error]);
        setFetchingNps(false);
      }
    }
    getKitNps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getKitSurveys() {
      setFetchingActiveSurveys(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/kits/${id}/surveys`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const surveys = await res.json();
        setActiveSurveys(surveys);
        setFetchingActiveSurveys(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setActiveSurveysErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetchingActiveSurveys(false);
      } else {
        const { error } = await res.json();
        setActiveSurveysErrors(prevArray => [...prevArray, error]);
        setFetchingActiveSurveys(false);
      }
    }
    getKitSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    async function getResearchStudies() {
      setFetchingResearchStudies(true);
      const res = await fetch(`${API_BASE_PATH}/ops/admins/research-studies`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-access-token': token,
          'Content-Type': 'application/json',
        },
      });
      if (res.status === 200) {
        const { researchStudies } = await res.json();
        setResearchStudies(researchStudies);
        setFetchingResearchStudies(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setResearchStudiesErrors([
          'There was a problem loading your information, please try again later or contact support.',
        ]);
        setFetchingResearchStudies(false);
      } else {
        const { error } = await res.json();
        setResearchStudiesErrors(prevArray => [...prevArray, error]);
        setFetchingResearchStudies(false);
      }
    }
    getResearchStudies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getPartnersList() {
      setFetchingPartners(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/partners?${queryBuilder({
          page: 0,
          pageLimit: 100,
          orderBy: 'name',
          order: 'asc',
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { partners } = await res.json();
        setPartners(partners);
        setFetchingPartners(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setPartnersErrors([
          'There was a problem loading the partners list, please try again later',
        ]);
        setFetchingPartners(false);
      } else {
        const { error } = await res.json();
        setPartnersErrors(prevArray => [...prevArray, error]);
        setFetchingPartners(false);
      }
    }
    getPartnersList();
  }, []);

  useEffect(() => {
    async function getPractitionersList() {
      setFetchingPractitioners(true);
      const res = await fetch(
        `${API_BASE_PATH}/ops/admins/practitioners?${queryBuilder({
          orderBy: 'user.first_name',
          order: 'asc',
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        },
      );
      if (res.status === 200) {
        const { practitioners } = await res.json();
        setPractitioners(practitioners);
        setFetchingPractitioners(false);
      } else if (res.status === 401) {
        await setAdmin(undefined, undefined);
      } else if (res.status === 500 || res.status === 404) {
        setPractitionersErrors([
          'There was a problem loading the practitioners list, please try again later',
        ]);
        setFetchingPractitioners(false);
      } else {
        const { error } = await res.json();
        setPractitionersErrors(prevArray => [...prevArray, error]);
        setFetchingPractitioners(false);
      }
    }
    getPractitionersList();
  }, []);

  const researchStudyOptions = researchStudies.map(researchStudy => ({
    value: researchStudy.id,
    title: researchStudy.name,
  }));

  researchStudyOptions.push({
    value: '',
    title: 'N/A',
  });

  return (
    <div className='flex flex-col flex-auto bg-gray-50 items-stretch max-w-full justify-center'>
      {deactivateOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>
                    Deactivate Kit {id}?
                  </span>
                </div>
                <div
                  onClick={() => setDeactivateOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  Are you sure you want to deactivate the kit{' '}
                  <span className='font-semibold'>{id}</span>? This can’t be
                  undone.
                </span>
              </div>
            </div>
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setDeactivateOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => deactivateKit()}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Deactivate
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {deletePDFOverlay ? (
        <div className='fixed inset-0 w-screen h-screen bg-black bg-opacity-50 z-50 items-center flex justify-center'>
          <div className='m-10 lg:w-full lg:max-w-xl relative bg-white rounded-md border border-solid border-gray-500 py-4'>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-2 px-4'>
                <div className='p-2 items-center flex justify-center'>
                  <span className='font-serif text-lg'>
                    Delete PDF for Kit {id}?
                  </span>
                </div>
                <div
                  onClick={() => setDeletePDFOverlay(false)}
                  className='ml-12 hover:bg-gray-300 hover:bg-opacity-50 rounded-md cursor-pointer p-2 items-center flex justify-center'
                >
                  <img
                    src={X}
                    className='relative block h-5 w-5'
                    alt='Pelago, smart packaging'
                  />
                </div>
              </div>
            </div>
            <div className='border-b border-solid border-gray-300'>
              <div className='w-full flex justify-between items-center py-6 px-4'>
                <span className='text-sm'>
                  Are you sure you want to delete the generated PDF?
                </span>
              </div>
            </div>
            <div className='flex justify-end px-4 pt-4'>
              <button
                onClick={() => setDeletePDFOverlay(false)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
              >
                Cancel
              </button>
              <button
                onClick={() => deletePDF()}
                className='ml-4 flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-pink-700 hover:bg-pink-900 rounded-md border border-solid border-pink-900 text-white transition-all duration-200 ease-in-out delay-75'
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ) : null}
      <div className='relative flex-auto mx-auto my-0 py-0 px-4 lg:px-12 max-w-6xl w-full'>
        <div className='pt-4 lg:pt-6 px-0 relative w-full'>
          <div className='sticky top-0 z-30 pb-2 w-full bg-gray-50'>
            <Breadcrumbs items={breadcrumbs} />
            <div className='flex justify-between'>
              <div className='mt-0 flex-auto self-center'>
                <h1 className='font-serif text-xl lg:text-2xl break-word leading-8 m-0'>
                  Kit {id}
                </h1>
              </div>
              <div className='flex flex-auto content-end items-start justify-end ml-6 whitespace-nowrap'>
                <div className='ml-2 lg:ml-4 mt-0 flex'>
                  <button
                    type='submit'
                    onClick={handleSubmit(updateKit)}
                    disabled={fetching}
                    className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75'
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 px-1'>
            {updated ? (
              <div className='bg-green-50 outline-none shadow-md rounded-md border border-solid border-green-500 mt-4'>
                <div className='flex flex-col p-6'>
                  <div className='font-medium'>
                    Kit {id} successfully updated
                  </div>
                </div>
              </div>
            ) : null}
            {errors && errors.length > 0 ? (
              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                <div className='flex flex-col p-3'>
                  <div className='font-semibold'>
                    There{' '}
                    {errors.length === 1
                      ? 'is 1 error'
                      : `are ${errors.length} errors`}{' '}
                    with this kit:
                  </div>
                  <div className='ml-4 pl-4'>
                    <ul className='list-disc'>
                      {errors.map((error, index) => {
                        return (
                          <li key={index} className='mt-2'>
                            {error}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <form autoComplete='off' className='mb-10 lg:flex'>
              <div className='flex flex-col lg:flex-row justify-center items-start lg:flex-1'>
                <div className='flex flex-col mt-6 lg:flex-2 w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          General information
                        </h2>
                      </div>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Kit Id</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {id}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Main Account ID
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.mainaccount ? params.mainaccount.id : 'N/A'}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Tiny Account ID
                          </label>
                          {params.tinyaccount_id ? (
                            <NavLink
                              to={`/tinyaccounts/${params.tinyaccount_id}`}
                              className='flex-auto relative w-full m-0 py-2 text-sm hover:text-purple-300'
                            >
                              {`${params.tinyaccount_id} (${params.tinyaccount?.first_name} ${params.tinyaccount?.last_name})`}
                            </NavLink>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Blocked by user?
                          </label>
                          <SelectInput
                            value={params.blocked_by_user}
                            register={{
                              ...register('blocked_by_user', {
                                onChange: e =>
                                  setParams(prevState => ({
                                    ...prevState,
                                    blocked_by_user: e.target.value,
                                  })),
                              }),
                            }}
                            isDirty={dirtyFields.blocked_by_user}
                            options={[
                              {
                                value: '',
                                title: '-- Choose a status --',
                                disabled: true,
                              },
                              { value: 1, title: 'Yes' },
                              { value: 0, title: 'No' },
                            ]}
                          />
                        </div>
                      </div>

                      <SelectInput
                        title='Status'
                        value={params.status}
                        register={{
                          ...register('status', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                status: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.status}
                        options={kitStatuses.map((status, index) => ({
                          value: status,
                          title: status,
                        }))}
                      />

                      {params.status === 'void' && (
                        <SelectInput
                          title='Void Reason'
                          value={params.void_reason || ''}
                          register={{
                            ...register('void_reason', {
                              onChange: e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  void_reason: e.target.value,
                                })),
                            }),
                          }}
                          isDirty={dirtyFields.status}
                          options={[
                            {
                              value: '',
                              title: '-- Choose a reason --',
                              disabled: true,
                            },
                            ...kitVoidReasons.map((reason, index) => ({
                              value: reason,
                              title: reason,
                            })),
                          ]}
                        />
                      )}

                      <SelectInput
                        title='Lab Partner'
                        value={params.lab_partner}
                        register={{
                          ...register('lab_partner', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                lab_partner: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.lab_partner}
                        options={validLabPartners.map((lab_partner, index) => ({
                          value: lab_partner.id,
                          title: lab_partner.label,
                        }))}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Partner</label>
                          {fetching || fetchingPartners ? (
                            'Loading partners list...'
                          ) : !partnersErrors?.length ? (
                            <PartnerDropdown
                              defaultValue={params.partner_id}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  partner_id: e?.value,
                                }))
                              }
                              partners={partners}
                            />
                          ) : (
                            <>
                              <p>{params.partner_id}</p>
                              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                                <div className='flex flex-col p-3'>
                                  <div className='ml-4 pl-4'>
                                    <ul className='list-disc'>
                                      {partnersErrors.map((error, idx) => (
                                        <li className='mt-2' key={idx}>
                                          {error}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <SelectInput
                        title='Expedited processing?'
                        value={params.expedited_processing}
                        register={{
                          ...register('expedited_processing', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                expedited_processing: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.expedited_processing}
                        options={[
                          {
                            value: '',
                            title: '-- Choose a status --',
                            disabled: true,
                          },
                          { value: 1, title: 'Yes' },
                          { value: 0, title: 'No' },
                        ]}
                      />

                      <SelectInput
                        title='Hold Release?'
                        value={params.hold_release}
                        register={{
                          ...register('hold_release', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                hold_release: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.hold_release}
                        options={[
                          {
                            value: '',
                            title: '-- Choose a status --',
                            disabled: true,
                          },
                          { value: 1, title: 'Yes' },
                          { value: 0, title: 'No' },
                        ]}
                      />

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Sample Label
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.sample_label ? params.sample_label : `N/A`}
                          </span>
                        </div>
                      </div>

                      <SelectInput
                        title='Sample Type'
                        value={params.sampling_type}
                        register={{
                          ...register('sampling_type', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                sampling_type: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.sampling_type}
                        options={[
                          {
                            value: '',
                            title: 'Choose Sampling type',
                            disabled: true,
                          },
                          { value: 'stool', title: 'Stool' },
                          {
                            value: 'stool_chemistry',
                            title: 'Stool Chemistry',
                          },
                          { value: 'vaginal', title: 'Vaginal' },
                        ]}
                      />

                      <TextInput
                        title='Sample date'
                        disabled={params.tinyaccount ? false : true}
                        register={{
                          ...register('sample_date', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                sample_date: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.sample_date}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.sample_date}
                      />

                      <SelectInput
                        title='Research Study'
                        value={params.research_study_id}
                        register={{
                          ...register('research_study_id', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                research_study_id: e.target.value,
                              })),
                          }),
                        }}
                        isDirty={dirtyFields.research_study_id}
                        options={researchStudyOptions}
                      />
                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Practitioner (Prompts user to add practitioner at
                            activation)
                          </label>
                          {fetching || fetchingPractitioners ? (
                            'Loading practitioners list...'
                          ) : !practitionersErrors?.length ? (
                            <PractitionerDropdown
                              defaultValue={params.practitioner_id}
                              onChange={e =>
                                setParams(prevState => ({
                                  ...prevState,
                                  practitioner_id: e?.value,
                                }))
                              }
                              practitioners={practitioners}
                            />
                          ) : (
                            <>
                              <p>{params.practitioner_id}</p>
                              <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
                                <div className='flex flex-col p-3'>
                                  <div className='ml-4 pl-4'>
                                    <ul className='list-disc'>
                                      {practitionersErrors.map((error, idx) => (
                                        <li className='mt-2' key={idx}>
                                          {error}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <TextInput
                        title='Comments'
                        type='textarea'
                        register={{
                          ...register('comments', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                comments: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='Internal comments'
                        value={params.comments}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.comments}
                      />
                    </div>
                  </div>
                  {variant && (
                    <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                      <div className='p-8 rounded-md'>
                        <div className='pb-4'>
                          <h2 className='font-serif font-semibold m-0 text-lg'>
                            Variant information
                          </h2>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Lab Partner
                            </label>
                            <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                              {variant.lab_partner}
                            </span>
                          </div>
                        </div>

                        <div className='flex-1 max-w-full mt-4'>
                          <div className='flex flex-col relative'>
                            <label className='mb-1 p-0 text-sm'>
                              Sampling Type
                            </label>
                            <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                              {variant.sampling_type}
                            </span>
                          </div>
                        </div>

                        <TextInput
                          title='Sample date'
                          register={{
                            ...register('variant_sample_date', {
                              onChange: e =>
                                updateDateParam(
                                  e.target.value,
                                  'sample_date',
                                  setVariant,
                                ),
                            }),
                          }}
                          placeholder='Must be in "YYYY-MM-DD" format'
                          value={variant.sample_date}
                          isDirty={dirtyFields.variant_sample_date}
                        />

                        <TextInput
                          title='Date delivered to lab'
                          register={{
                            ...register('variant_date_delivered_to_lab', {
                              onChange: e =>
                                updateDateParam(
                                  e.target.value,
                                  'date_delivered_to_lab',
                                  setVariant,
                                ),
                            }),
                          }}
                          placeholder='Must be in "YYYY-MM-DD" format'
                          value={variant.date_delivered_to_lab}
                          isDirty={dirtyFields.variant_date_delivered_to_lab}
                        />
                        <TextInput
                          title='Date results ready'
                          disabled={params.tinyaccount ? false : true}
                          register={{
                            ...register('variant_date_results_ready', {
                              onChange: e =>
                                setVariant(prevState => ({
                                  ...prevState,
                                  date_results_ready: e.target.value,
                                })),
                            }),
                          }}
                          placeholder='Must be in "YYYY-MM-DD" format'
                          value={variant.date_results_ready}
                          className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          isDirty={dirtyFields.variant_date_results_ready}
                        />

                        <TextInput
                          title='Tracking number to lab'
                          register={{
                            ...register('variant_tracking_number_to_lab', {
                              onChange: e =>
                                setVariant(prevState => ({
                                  ...prevState,
                                  tracking_number_to_lab: e.target.value,
                                })),
                            }),
                          }}
                          placeholder='usps-tracking-number'
                          value={variant.tracking_number_to_lab}
                          className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                          isDirty={dirtyFields.variant_tracking_number_to_lab}
                        />
                      </div>
                    </div>
                  )}
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          New Surveys
                        </h2>
                      </div>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative'>
                          {fetchingActiveSurveys ? (
                            'Loading Surveys...'
                          ) : (
                            <ul>
                              {activeSurveys &&
                                activeSurveys.needed &&
                                activeSurveys.needed.map((survey, index) => {
                                  const is_completed =
                                    activeSurveys.completed.filter(
                                      obj =>
                                        obj.typeform_id === survey.typeform_id,
                                    ).length > 0;
                                  let completed_dt = 'incomplete';
                                  if (is_completed) {
                                    const completed_survey =
                                      params.tinyaccount?.typeform_survey_responses.filter(
                                        obj =>
                                          obj.form_id === survey.typeform_id &&
                                          (obj.kit_id
                                            ? obj.kit_id === id
                                            : true),
                                      );
                                    if (completed_survey?.length > 0) {
                                      completed_dt = `completed ${moment(
                                        completed_survey[0].created_at,
                                      ).format('YYYY-MM-DD')}`;
                                    }
                                  }
                                  return (
                                    <li key={index}>
                                      {is_completed ? (
                                        <Check
                                          stroke='green'
                                          className={
                                            'w-5 h-5 mr-2 align-middle inline-block'
                                          }
                                        />
                                      ) : (
                                        <Circle
                                          stroke='red'
                                          className={
                                            'w-5 h-5 mr-2 align-middle inline-block'
                                          }
                                        />
                                      )}

                                      <label className='mb-1 p-0 text-sm'>
                                        {survey.display_title}
                                      </label>
                                      <label className='mb-1 p-0 text-sm'>
                                        {' '}
                                        ({survey.typeform_id})
                                      </label>
                                      <label className='ml-2 mb-1 p-0 text-sm text-gray-500'>
                                        {completed_dt}
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Old Surveys
                        </h2>
                      </div>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative'>
                          <ul>
                            {params.survey_status &&
                              params.survey_status.needed &&
                              params.survey_status.needed.map(
                                (survey, index) => {
                                  const is_completed =
                                    params.survey_status.completed.filter(
                                      obj =>
                                        obj.typeform_id === survey.typeform_id,
                                    ).length > 0;
                                  let completed_dt = 'incomplete';
                                  if (is_completed) {
                                    const completed_survey =
                                      params.tinyaccount?.typeform_survey_responses.filter(
                                        obj =>
                                          obj.form_id === survey.typeform_id,
                                      );
                                    if (completed_survey.length > 0) {
                                      completed_dt = `completed ${moment(
                                        completed_survey[0].created_at,
                                      ).format('YYYY-MM-DD')}`;
                                    }
                                  }
                                  return (
                                    <li key={index}>
                                      {is_completed ? (
                                        <Check
                                          stroke='green'
                                          className={
                                            'w-5 h-5 mr-2 align-middle inline-block'
                                          }
                                        />
                                      ) : (
                                        <Circle
                                          stroke='red'
                                          className={
                                            'w-5 h-5 mr-2 align-middle inline-block'
                                          }
                                        />
                                      )}

                                      <label className='mb-1 p-0 text-sm'>
                                        {survey.display_title}
                                      </label>
                                      <label className='mb-1 p-0 text-sm'>
                                        {' '}
                                        ({survey.typeform_id})
                                      </label>
                                      <label className='ml-2 mb-1 p-0 text-sm text-gray-500'>
                                        {completed_dt}
                                      </label>
                                    </li>
                                  );
                                },
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Feedback
                        </h2>
                        {fetchingFeedback ? (
                          'Loading Feedback...'
                        ) : feedback?.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'feedback'}
                                  columns={feedbackTableColumns}
                                  staticData={feedback}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          'No Feedback'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          NPS Rating
                        </h2>
                        {fetchingNps ? (
                          'Loading NPS Ratings...'
                        ) : nps?.length > 0 ? (
                          <div className='flex-1 max-w-full'>
                            <div className='flex flex-col relative'>
                              <div className='w-full border-collapse'>
                                <Table
                                  type={'nps'}
                                  columns={npsTableColumns}
                                  staticData={nps}
                                ></Table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          'No NPS Ratings'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 mt-4'>
                    <div className='p-8 rounded-md'>
                      <div className='pb-4'>
                        <h2 className='font-serif font-semibold m-0 text-lg'>
                          Order information
                        </h2>
                      </div>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Order number
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.order_number ? params.order_number : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Buyer email
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.buyer_email ? params.buyer_email : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Buyer phone
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.buyer_phone ? params.buyer_phone : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Buyer country
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.buyer_country_code
                              ? params.buyer_country_code
                              : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Item name</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.item_name ? params.item_name : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>List price</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.list_price ? params.list_price : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Purchase price
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.purchase_price === 0
                              ? `FREE`
                              : params.purchase_price
                              ? `$${params.purchase_price}`
                              : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Discount code
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.discount_code
                              ? params.discount_code
                              : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Subscription
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.subscription ? `Yes` : `No`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>Order ID</label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.order_id ? params.order_id : `N/A`}
                          </span>
                        </div>
                      </div>

                      <div className='flex-1 max-w-full mt-4'>
                        <div className='flex flex-col relative'>
                          <label className='mb-1 p-0 text-sm'>
                            Order item ID
                          </label>
                          <span className='flex-auto relative w-full m-0 py-2 text-sm'>
                            {params.order_item_id
                              ? params.order_item_id
                              : `N/A`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='flex lg:mt-6 mt-4 lg:ml-8 lg:flex-1 flex-col w-full'>
                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1'>
                    <div className='px-4 lg:px-8 pt-8'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Tracking
                      </h2>
                    </div>
                    <div className='px-4 lg:px-8 pt-4 pb-8'>
                      <TextInput
                        title='Tracking number to user'
                        register={{
                          ...register('tracking_number_to_user', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                tracking_number_to_user: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='usps-tracking-number'
                        value={params.tracking_number_to_user}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.tracking_number_to_user}
                      />

                      <TextInput
                        title='Tracking number to lab'
                        register={{
                          ...register('tracking_number_to_lab', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                tracking_number_to_lab: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='usps-tracking-number'
                        value={params.tracking_number_to_lab}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.tracking_number_to_lab}
                      />

                      <TextInput
                        title='Outbound Fulfillment ID'
                        register={{
                          ...register('fulfillment_id', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                fulfillment_id: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='shopify-fulfillment-id'
                        value={params.fulfillment_id}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.fulfillment_id}
                      />

                      <TextInput
                        title='Bioinformatics Pipeline ID'
                        register={{
                          ...register('bioinformatics_pipeline_id', {
                            onChange: e =>
                              setParams(prevState => ({
                                ...prevState,
                                bioinformatics_pipeline_id: e.target.value,
                              })),
                          }),
                        }}
                        placeholder='bioinformatics-pipeline-id'
                        value={params.bioinformatics_pipeline_id}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.bioinformatics_pipeline_id}
                      />
                    </div>
                  </div>

                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1 mt-4'>
                    <div className='px-4 lg:px-8 pt-8'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Expert review
                      </h2>
                    </div>
                    <div className='px-4 lg:px-8 pt-4 pb-8'>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'>
                          <NavLink to={`/kits/${id}/note`}>
                            <span
                              className={`flex text-sm text-center justify-center rounded-2xl p-2 bg-${
                                params.note?.status === 'draft'
                                  ? 'yellow'
                                  : params.note?.status === 'published'
                                  ? 'green'
                                  : 'red'
                              }-100 mb-1 w-24`}
                            >
                              {params.note ? params.note.status : 'missing'}
                            </span>
                            {params.note && (
                              <span className='flex mb-1 p-0 text-sm'>
                                Updated {params.note.updated_at}
                              </span>
                            )}
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1 mt-4'>
                    <div className='px-4 lg:px-8 pt-8'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        PDF report
                      </h2>
                    </div>
                    <div className='px-4 lg:px-8 pt-4 pb-8'>
                      <div className='flex-1 max-w-full'>
                        <div className='flex flex-col relative m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'>
                          {!params.pdf_url && (
                            <button
                              disabled={isGeneratingPDF}
                              type='button'
                              onClick={() => generatePDF()}
                              className={`flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content ${
                                isGeneratingPDF
                                  ? ''
                                  : 'bg-green-100 hover:bg-green-50'
                              } rounded-md border border-solid border-green-900 text-green-900 transition-all duration-200 ease-in-out delay-75`}
                            >
                              {isGeneratingPDF ? 'Generating...' : 'Generate'}
                            </button>
                          )}
                          {params.pdf_url && (
                            <div className='flex flex-row justify-between items-start flex-1'>
                              <div>
                                <button
                                  type='button'
                                  onClick={() => viewPDF()}
                                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-green-100 hover:bg-green-50 rounded-md border border-solid border-green-900 text-green-900 transition-all duration-200 ease-in-out delay-75'
                                >
                                  View
                                </button>
                              </div>
                              <div>
                                <button
                                  type='button'
                                  onClick={() => setDeletePDFOverlay(true)}
                                  className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-pink-50 rounded-md border border-solid border-pink-900 text-pink-900 transition-all duration-200 ease-in-out delay-75'
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300 flex flex-col flex-1 mt-4'>
                    <div className='px-4 lg:px-8 pt-8'>
                      <h2 className='font-serif font-semibold m-0 text-lg'>
                        Status changes
                      </h2>
                    </div>
                    <div className='px-4 lg:px-8 pt-4 pb-8'>
                      <TextInput
                        disabled={true}
                        title='Date last status change'
                        value={params.date_last_status_change}
                      />

                      <TextInput
                        register={{
                          ...register('date_purchased', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_purchased',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date purchased'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_purchased}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.date_purchased}
                      />

                      <TextInput
                        register={{
                          ...register('date_voided', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_voided',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date voided'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_voided}
                        className='flex-auto relative w-full m-0 py-2 px-4 border border-solid border-gray-300 rounded-md text-sm'
                        isDirty={dirtyFields.date_voided}
                        disabled={params.status !== 'void'}
                      />

                      <TextInput
                        register={{
                          ...register('date_in_transit_to_customer', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_in_transit_to_customer',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date in transit to customer'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_in_transit_to_customer}
                        isDirty={dirtyFields.date_in_transit_to_customer}
                      />

                      <TextInput
                        register={{
                          ...register('date_delivered_to_customer', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_delivered_to_customer',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date delivered to customer'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_delivered_to_customer}
                        isDirty={dirtyFields.date_delivered_to_customer}
                      />

                      <TextInput
                        register={{
                          ...register('date_activated', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_activated',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date activated'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_activated}
                        isDirty={dirtyFields.date_activated}
                      />

                      <TextInput
                        register={{
                          ...register('date_in_transit_to_lab', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_in_transit_to_lab',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date in transit to lab'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_in_transit_to_lab}
                        isDirty={dirtyFields.date_in_transit_to_lab}
                      />

                      <TextInput
                        register={{
                          ...register('date_delivered_to_lab', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_delivered_to_lab',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date delivered to lab'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_delivered_to_lab}
                        isDirty={dirtyFields.date_delivered_to_lab}
                      />

                      <TextInput
                        register={{
                          ...register('date_sequences_delivered', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_sequences_delivered',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date sequences delivered'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_sequences_delivered}
                        isDirty={dirtyFields.date_sequences_delivered}
                      />

                      <TextInput
                        register={{
                          ...register('date_pipeline_complete', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_pipeline_complete',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date pipeline complete'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_pipeline_complete}
                        isDirty={dirtyFields.date_pipeline_complete}
                      />

                      <TextInput
                        register={{
                          ...register('date_results_ready_practitioner', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_results_ready_practitioner',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date results ready practitioner'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_results_ready_practitioner}
                        isDirty={dirtyFields.date_results_ready_practitioner}
                      />

                      <TextInput
                        register={{
                          ...register('date_results_ready', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_results_ready',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date results ready'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_results_ready}
                        isDirty={dirtyFields.date_results_ready}
                      />

                      <TextInput
                        register={{
                          ...register('date_expert_review_complete', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_expert_review_complete',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date expert review complete'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_expert_review_complete}
                        isDirty={dirtyFields.date_expert_review_complete}
                      />

                      <TextInput
                        register={{
                          ...register('date_return_missing', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_return_missing',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date return missing'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_return_missing}
                        isDirty={dirtyFields.date_return_missing}
                      />

                      <TextInput
                        register={{
                          ...register('date_bioinformatics_hold', {
                            onChange: e =>
                              updateDateParam(
                                e.target.value,
                                'date_bioinformatics_hold',
                                setParams,
                              ),
                          }),
                        }}
                        title='Date bioinformatics hold'
                        placeholder='Must be in "YYYY-MM-DD" format'
                        value={params.date_bioinformatics_hold}
                        isDirty={dirtyFields.date_bioinformatics_hold}
                      />

                      <TextInput
                        disabled={true}
                        title='Date Surveys Completed'
                        value={params.date_survey_completed}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className='mb-8'>
              <button
                onClick={() => setDeactivateOverlay(true)}
                className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-pink-50 rounded-md border border-solid border-pink-900 text-pink-900 transition-all duration-200 ease-in-out delay-75'
              >
                Deactivate kit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
