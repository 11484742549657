interface PageDetailCardProps {
  column: number;
  title: string;
  subtitle?: string;
  className?: string;
  innerClassName?: string;
  children?: React.ReactNode;
}

const PageDetailCard = ({
  column,
  title,
  subtitle,
  className,
  innerClassName,
  children,
}: PageDetailCardProps) => {
  return (
    <div className={`flex flex-col lg:flex-${column} w-full mt-6 ${className}`}>
      <div className='bg-white outline-none shadow-md rounded-md border border-solid border-gray-300'>
        <div className='p-8 rounded-md' data-color-mode='light'>
          <div className={`pb-4 ${innerClassName}`}>
            <h2 className='font-serif font-semibold m-0 text-lg'>{title}</h2>
            <label className='mb-1 p-0 text-sm opacity-60'>{subtitle}</label>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageDetailCard;
