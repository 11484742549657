import PageListView from '../../components/Page/PageListView';
import ImpersonatePartnerButton from '../../components/ImpersonateButton/ImpersonatePartnerButton';

const columns = [
  {
    Header: 'ID',
    Cell: ({ row }: { row: any }) => (
      <a href={`/partners/${row.original.id}`}>{row.original.id}</a>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Slug',
    accessor: 'slug',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Actions',
    id: 'actions',
    Cell: ({ row }: { row: any }) => {
      console.log(row.original);
      return (
        <div className='flex space-x-2'>
          <ImpersonatePartnerButton
            partnerId={row.original.id}
            partnerAdminUserId={row.original.admin_user_id}
            partnerName={row.original.name}
            buttonSize='small'
          />
        </div>
      );
    },
  },
];

export default function Partners() {
  return (
    <PageListView
      header='Partners'
      rightButton={{
        title: 'Create New',
        url: '/partners/new',
      }}
      tableProps={{
        type: 'partners',
        route: 'partners',
        noRowClick: true,
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Name',
            filter: 'name',
          },
          {
            label: 'Slug',
            filter: 'slug',
          },
        ],
      }}
    />
  );
}
