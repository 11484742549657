import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { API_BASE_PATH } from '../../config/api';
import useDataHandling from '../../hooks/useDataHandling';
import TextInput from '../../components/TextInput/TextInput';
import SelectInput from '../../components/SelectInput/SelectInput';
import useStore from '../../stores';
import { PortalConfig } from '../Partner';

const KIT_TYPES: { value: string; label: string }[] = [
  {
    value: 'gut',
    label: 'Gut Health Kit (Practitioner)',
  },
  {
    value: 'vaginal',
    label: 'Vaginal Health (Test Practitioner)',
  },
  {
    value: 'pro',
    label: 'Tiny Health PRO gut health test',
  },
  { value: 'replacement_gut', label: 'Replacement Gut Swab' },
  { value: 'replacement_vaginal', label: 'Replacement Vaginal Swab' },
  { value: 'replacement_pro', label: 'Replacement PRO Gut Health Test' },
];

const COUNTRY_OPTIONS = [
  { value: 'US', title: 'United States' },
  { value: 'CA', title: 'Canada' },
];

type ParterOrderFormData = {
  partnerId?: string;
  kits: {
    kit_type: string;
    quantity: number;
  }[];

  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country: string;
  external_kit_id?: string;
  comments?: string;
  outbound_label: boolean;
  disable_outbound_label?: boolean;
};
export type EmailType = 'email' | 'sms';

export type EmailStatus =
  | 'processed'
  | 'bounce'
  | 'delivered'
  | 'unsubscribe'
  | 'spamreport';
export type Email = {
  clicks: number;
  id: string;
  opens: number;
  status: EmailStatus;
  type: EmailType;
  /* OPTIONAL */
  bounced_at?: string;
  created_at?: string;
  delivered_at?: string;
  kit_id?: string;
  email?: string;
  phone_number?: string;
  processed_at?: string;
  spam_at?: string;
  template_id?: string;
  unsubscribed_at?: string;
  updated_at?: string;
};
export type Partner = {
  id: string;
  name: string;
  slug: string;
  report_config: Record<string, unknown>;
  portal_config: PortalConfig;
  status: string;
  email: string;
  api_key: string;
  logo: string;
  light_logo: string;
  pdf_logo: string;
  kit_activation_instructions: string;
  mainaccount_id: string;
  hide_consults: boolean | number;
  typeform_adult_survey_id: string;
  typeform_toddler_survey_id: string;
  typeform_vaginal_survey_id: string;
  instructions_url: string;
  shipping_level: string;
  admin_user_id: string;
  emails?: Email[];
  sms?: Email[];
};
const PartnerOrders = () => {
  const history = useHistory();

  const token = useStore(state => state.admin.token);
  const {
    data: partners,
    fetching: loading,
    errors,
    fetchData,
  } = useDataHandling<Partner[]>('partners', '', 'partners', {
    page: 0,
    pageLimit: 50,
    filterType: 'all',
    filterValue: '',
    orderBy: 'name',
    order: 'asc',
  });
  const { control, handleSubmit, register, watch, formState } =
    useForm<ParterOrderFormData>({
      defaultValues: {
        partnerId: undefined,
        kits: KIT_TYPES.map(kit => ({ kit_type: kit.value, quantity: 0 })),
        outbound_label: true, // Default to true for outbound label
        country: COUNTRY_OPTIONS[0]?.value,
      },
    });
  const { errors: formErrors } = formState;
  React.useEffect(() => {
    fetchData();
  }, []);
  const watchKits = watch('kits');
  const totalKits = watchKits?.reduce(
    (sum, kit) => sum + (Number(kit.quantity) || 0),
    0,
  );
  const isMultipleKits = totalKits > 1;
  const watchOutboundLabel = watch('outbound_label');
  const hideAddressFields = !watchOutboundLabel || isMultipleKits;

  const onSubmit = async (data: ParterOrderFormData) => {
    try {
      // Filter out kits with quantity 0
      const filteredKits = data.kits.filter(kit => kit.quantity > 0);

      const formData = {
        partnerId: data.partnerId,
        kits: filteredKits,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        country: data.country,
        external_kit_id: data.external_kit_id,
        comments: data.comments,
        disable_outbound_label: !(data.outbound_label && !isMultipleKits),
      };

      // Only include address fields if outbound label is checked
      if (!hideAddressFields) {
        Object.assign(formData, {
          street1: data.street1,
          street2: data.street2,
          city: data.city,
          state: data.state,
          zip: data.zip,
        });
      }

      const response = await fetch(
        `${API_BASE_PATH}/ops/admins/partner-orders`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'x-access-token': token ?? '',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to create order');
      }

      await toast.success('Partner order created successfully');
      history.push('/partner_invoices');
    } catch (error) {
      console.error('Error creating partner order:', error);
      await toast.error('Failed to create partner order');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  if (errors.length > 0) {
    return <div className='text-red-500'>{errors.join(', ')}</div>;
  }
  if (!partners?.length) {
    return <div>No partners found</div>;
  }
  return (
    <div className='container mx-auto px-4 py-8'>
      <h1 className='text-2xl font-bold mb-8'>Create Partner Order</h1>

      <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
        <div className='bg-white p-6 rounded-lg shadow'>
          <h2 className='text-xl font-semibold mb-4'>Partner Selection</h2>
          <Controller
            {...register('partnerId', { required: 'Please select a partner' })}
            control={control}
            render={({ field }) => (
              <SelectInput
                title='Partner'
                placeholder='Select a partner'
                value={field.value}
                onChange={field.onChange}
                options={partners.map(partner => ({
                  value: partner.id,
                  title: partner.name,
                }))}
              >
                {formErrors.partnerId && (
                  <span className='text-red-500 text-xs mt-1'>
                    {formErrors.partnerId.message}
                  </span>
                )}
              </SelectInput>
            )}
          />
        </div>

        <div className='bg-white p-6 rounded-lg shadow'>
          <h2 className='text-xl font-semibold mb-4'>Kit Selection</h2>
          <div className='space-y-4'>
            {KIT_TYPES.map((kitType, index) => (
              <Controller
                key={kitType.value}
                name={`kits.${index}.quantity`}
                control={control}
                rules={{ required: true, min: 0, max: 10 }}
                render={({ field }) => (
                  <div
                    className='flex justify-between items-center gap-2'
                    style={{ width: '24rem' }}
                  >
                    {kitType.label}
                    <div style={{ width: '4rem' }}>
                      <TextInput
                        // title={kitType.label}
                        type='number'
                        min={0}
                        max={10}
                        value={field.value}
                        onChange={field.onChange}
                        maxLength={2}
                        error={
                          formErrors.kits?.[index]?.quantity
                            ? 'Quantity must be between 0 and 10'
                            : undefined
                        }
                      />
                    </div>
                  </div>
                )}
              />
            ))}
          </div>
        </div>

        <div className='bg-white p-6 rounded-lg shadow'>
          <h2 className='text-xl font-semibold mb-4'>Recipient Information</h2>
          <Controller
            {...register('outbound_label')}
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <div className='mb-4 flex items-center'>
                <label className='cursor-pointer label mr-2' htmlFor={name}>
                  <span className='label-text'>Outbound label needed</span>
                </label>

                <input
                  type='checkbox'
                  id={name}
                  className='checkbox'
                  checked={value}
                  value={name}
                  onChange={e => onChange(e.target.checked)}
                  disabled={isMultipleKits}
                />
                {isMultipleKits && (
                  <span className='text-gray-600 text-sm ml-3'>
                    Outbound label is disabled for multiple kit orders
                  </span>
                )}
              </div>
            )}
          />
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            <Controller
              {...register('first_name')}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  title='First Name'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    formErrors.first_name ? 'First name is required' : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('last_name')}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  title='Last Name'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    formErrors.last_name ? 'Last name is required' : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('email')}
              control={control}
              rules={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              }}
              render={({ field }) => (
                <TextInput
                  title='Email'
                  type='email'
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    formErrors.email?.type === 'required'
                      ? 'Email is required'
                      : formErrors.email?.type === 'pattern'
                        ? 'Please enter a valid email address'
                        : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('phone')}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextInput
                  title='Phone Number'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  error={
                    formErrors.phone ? 'Phone number is required' : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('street1')}
              control={control}
              rules={{ required: watchOutboundLabel && !isMultipleKits }}
              render={({ field }) => (
                <TextInput
                  title='Address Line 1'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  hidden={hideAddressFields}
                  error={
                    formErrors.street1
                      ? 'Address is required when outbound label is needed'
                      : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('street2')}
              control={control}
              render={({ field }) => (
                <TextInput
                  title='Address Line 2'
                  subtitle='Optional'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  hidden={hideAddressFields}
                />
              )}
            />
            <Controller
              {...register('city')}
              control={control}
              rules={{ required: watchOutboundLabel && !isMultipleKits }}
              render={({ field }) => (
                <TextInput
                  title='City'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  hidden={hideAddressFields}
                  error={
                    formErrors.city
                      ? 'City is required when outbound label is needed'
                      : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('state')}
              control={control}
              rules={{ required: watchOutboundLabel && !isMultipleKits }}
              render={({ field }) => (
                <TextInput
                  title='State'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  hidden={hideAddressFields}
                  error={
                    formErrors.state
                      ? 'State is required when outbound label is needed'
                      : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('zip')}
              control={control}
              rules={{ required: watchOutboundLabel && !isMultipleKits }}
              render={({ field }) => (
                <TextInput
                  title='ZIP Code'
                  type='text'
                  value={field.value}
                  onChange={field.onChange}
                  hidden={hideAddressFields}
                  error={
                    formErrors.zip
                      ? 'ZIP code is required when outbound label is needed'
                      : undefined
                  }
                />
              )}
            />
            <Controller
              {...register('country')}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectInput
                  title='Country'
                  value={field.value}
                  onChange={field.onChange}
                  options={COUNTRY_OPTIONS}
                >
                  {formErrors.country && (
                    <span className='text-red-500 text-xs mt-1'>
                      Country is required
                    </span>
                  )}
                </SelectInput>
              )}
            />
          </div>
        </div>

        <div className='bg-white p-6 rounded-lg shadow'>
          <h2 className='text-xl font-semibold mb-4'>Additional Information</h2>
          <Controller
            {...register('comments')}
            control={control}
            render={({ field }) => (
              <TextInput
                title='Comments'
                subtitle='Optional'
                type='textarea'
                value={field.value}
                onChange={field.onChange}
                className='h-24'
              />
            )}
          />
        </div>

        <button
          type='submit'
          className='h-10 px-2 lg:px-5 text-sm bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
          onClick={handleSubmit(onSubmit)}
        >
          Create Order
        </button>
      </form>
    </div>
  );
};

export default PartnerOrders;
